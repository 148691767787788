<template>
  <div class="inner-section">
         <card>
           <template v-slot:searchHeaderTitle>
              <h4 class="card-title">{{ $t('elearning_tim.certificate') }} {{ $t('globalTrans.search') }}</h4>
            </template>
            <template v-slot:searchBody>
                <b-row>
                  <b-col lg="6" sm="6">
                  <ValidationProvider name="Fiscal Year" vid="fiscal_year" rules="required">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="fiscal_year"
                        slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{$t('globalTrans.fiscal_year')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                          plain
                          v-model="search.fiscal_year_id"
                          :options="fiscalYearList"
                          id="fiscal_year"
                          :state="errors[0] ? false : (valid ? true : null)"
                        >
                        <template v-slot:first>
                          <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col lg="6" sm="6">
                  <ValidationProvider name="Circular Memo No" vid="circular_memo_no" rules="required">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="circular_memo_no"
                        slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{$t('elearning_iabm.circular_memo_no')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                          plain
                          v-model="search.circular_memo_no"
                          :options="circularList"
                          id="circular_memo_no"
                          :state="errors[0] ? false : (valid ? true : null)"
                        >
                        <template v-slot:first>
                          <b-form-select-option value=''>{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                    <!-- <b-col lg="6" sm="6">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="circular_memo_no"
                      >
                      <template v-slot:label>
                        {{$t('elearning_iabm.circular_memo_no')}}
                      </template>
                      <b-form-input
                        id="circular_memo_no"
                        v-model.lazy="search.circular_memo_no"
                        :placeholder="$t('elearning_iabm.circular_memo_no')"
                      ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="fiscal_year_id"
                      >
                        <template v-slot:label>
                          {{$t('elearning_config.fiscal_year')}}
                        </template>
                        <v-select name="organization"
                          v-model="search.fiscal_year_id"
                          label="text"
                          :reduce="item => item.value"
                          :options= fiscalYearList
                          :placeholder="$t('globalTrans.select')"
                        />
                      </b-form-group>
                    </b-col> -->
                    <b-col lg="6" sm="12" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1 || $store.state.Auth.authUser.org_id === 12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          :label="$t('elearning_config.organization')"
                          label-for="organization"
                      >
                        <v-select name="organization"
                            v-model="search.org_id"
                            label="text"
                            :reduce="item => item.value"
                            :options= orgList
                            :placeholder="$t('globalTrans.select')"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="training_type_id"
                      >
                        <template v-slot:label>
                          {{$t('elearning_config.training_type')}}
                        </template>
                        <b-form-select
                          plain
                          v-model="search.training_type_id"
                          :options="trainingTypeList"
                          id="training_type_id"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select>
                      </b-form-group>
                    </b-col>
                    <!-- <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="training_category_id"
                        >
                          <template v-slot:label>
                            {{$t('elearning_config.training_category')}}
                          </template>
                          <b-form-select
                            plain
                            v-model="search.training_category_id"
                            :options="trainingCategoryList"
                            id="training_category_id"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                      </b-form-group>
                    </b-col> -->
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="training_title_id"
                        >
                          <template v-slot:label>
                            {{$t('elearning_config.training_title')}}
                          </template>
                          <b-form-select
                            plain
                            v-model="search.training_title_id"
                            :options="trainingTitleList"
                            id="training_title_id"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                      </b-form-group>
                    </b-col>
                    <!-- <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="mobile_no"
                        >
                          <template v-slot:label>
                            {{$t('globalTrans.mobile')}}
                          </template>
                          <b-form-input
                            id="mobile_no"
                            v-model="search.mobile_no"
                            :placeholder="$t('globalTrans.mobile')"
                          ></b-form-input>
                      </b-form-group>
                    </b-col> -->
                    <b-col lg="12" sm="12" class="text-right">
                        <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                    </b-col>
                </b-row>
            </template>
        </card>
        <body-card>
          <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('elearning_tim.certificate_list') }}</h4>
          </template>
          <template v-slot:headerAction>
            <router-link to="certificate-generate-form" :class="'btn btn_add_new'">
              <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
            </router-link>
          </template>
          <template v-slot:body>
              <b-overlay :show="loadingState">
                <b-row>
                    <b-col sm="12">
                    <div class="quick-filter-wrapper">
                        <div class="quick-filter-left">
                        <b-dropdown split split-variant="outline-secondary" size="sm" id="dropdown-form" :text="$t('globalTrans.column_visibility')" ref="dropdown">
                            <b-dropdown-form>
                            <div
                                class="form-group"
                                v-for="(field, index) in labelData"
                                :key="index"
                                >
                                <b-form-checkbox
                                    :id="'checkbox-' + field.label"
                                    v-model="field.show"
                                    :name="'checkbox-' + field.label"
                                    value=1
                                    unchecked-value=0
                                >
                                    {{ ($i18n.locale==='bn') ? field.label : field.label }}
                                </b-form-checkbox>
                            </div>
                            </b-dropdown-form>
                        </b-dropdown>
                        </div>
                        <div class="quick-filter-right">
                        <b-form-group
                            :label="$t('menu.perpage')"
                            label-for="per-page-select"
                        >
                            <b-form-select
                            id="per-page-select"
                            v-model="search.limit"
                            :options="pageOptions"
                            size="sm"
                            ></b-form-select>
                        </b-form-group>
                        </div>
                    </div>
                    </b-col>
                </b-row>
                  <b-row>
                      <b-col md="12" class="table-responsive">
                        <slot v-if ="columns.filter(item => item.show === '1').length > 0">
                          <b-table :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" thead-class="bg-primary" bordered hover :items="listData" :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                              <template v-slot:cell(serial)="data">
                              {{ $n(data.item.serial + pagination.slOffset) }}
                              </template>
                              <template v-slot:cell(type_name)="data">
                              <span class="capitalize">{{ data.item.type_name }}</span>
                              </template>
                              <template v-slot:cell(action)="data">
                                  <!-- <router-link :to="`certificate-generate-details-two?circilarMemoNo=${data.item}`" :class="'btn btn-success text-light'"><i class="fas fa-eye"></i></router-link> -->
                                  <!-- <router-link @click.native="remove(data.item)" :to="{path: 'certificate?circilarMemoNo' + data.item, query: {item: data.item, index: data, circular_memo_no: data.item.circular_memo_no, itemtwo: data }}" :class="'btn btn-success text-light'"><i class="fas fa-eye"></i></router-link> -->
                                  <router-link @click.native="remove(data.item)" :to="`/training-e-learning-service/tim/certificate?id=${data.item.id}&circular_memo_no=${data.item.circular_memo_no}`" :id="data.item.id" :class="'btn btn-success text-light'"><i class="fas fa-eye"></i></router-link>
                              </template>
                          </b-table>
                        </slot>
                          <div class="pagination-wrapper mt-3" v-if="columns.filter(item => item.show === '1').length > 0">
                          <b-pagination
                            v-model="pagination.currentPage"
                            :perPage="search.limit"
                            :total-rows="pagination.totalRows"
                            @input="searchData"
                          />
                        </div>
                      </b-col>
                  </b-row>
              </b-overlay>
          </template>
        </body-card>
        <b-modal id="modal-4" size="lg" :title="$t('elearning_tpm.course_managment') + ' ' + $t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <b-button @click="pdfExport" class="ml-4 btn-success water-test-pdf-button">
              {{  $t('globalTrans.export_pdf') }}
            </b-button>
            <Details :id="editItemId" :key="editItemId"  ref="details"/>
        </b-modal>
        <b-modal id="modal-6" size="lg" :title="$t('globalTrans.description')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <p>
            <Description :item="item" :key="item.id"/>
        </p>
        </b-modal>
    <!-- <pre>{{listData}}</pre> -->
  </div>
</template>
<script>

import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { certificateIndividualList, circularPublicationList, circularData } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/list'
import Details from './Details'
import Description from './Description'
import Store from '@/store'

export default {
    mixins: [ModalBaseMasterList],
    components: {
      Details, Description
    },
    data () {
        return {
          sortBy: '',
          sortDirection: '',
          sortDesc: '',
          search: {
            circular_memo_no: '',
            org_id: [],
            training_type_id: 0,
            training_title_id: 0,
            fiscal_year_id: [],
            limit: 20
          },
          circularLoading: false,
          item: [],
          circularList: [],
          labelData: [
          { labels: 'globalTrans.sl_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '10%' } },
          { labels: 'elearning_iabm.registration_for', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '30%' } },
          { labels: 'elearning_config.fiscal_year', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: '35%' } },
          { labels: 'elearning_iabm.circular_memo_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: '35%' } },
          { labels: 'elearning_config.organization', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 5, thStyle: { width: '35%' } },
          { labels: 'elearning_config.training_title', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 6, thStyle: { width: '35%' } },
          { labels: 'elearning_tim.name', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 6, thStyle: { width: '35%' } },
          { labels: 'globalTrans.mobile', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 6, thStyle: { width: '35%' } },
          // { labels: 'globalTrans.status', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 7, thStyle: { width: '10%' } },
          { labels: 'globalTrans.action', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 8, thStyle: { width: '10%' } }
        ]
        }
    },
    computed: {
      trainingTypeList: function () {
        return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
      },
      trainingTitleList: function () {
        return this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
      },
      fiscalYearList: function () {
        return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
      },
      orgList: function () {
        return this.$store.state.CommonService.commonObj.componentOrgList.filter(item => item.status === 1)
      },
      trainerEvaluationList: function () {
        return this.$store.state.TrainingElearning.commonObj.trainerEvaluationList.filter(item => item.status === 1)
      },
      formTitle () {
          return (this.editItemId === 0) ? this.$t('elearning_config.trainer_evaluation') + ' ' + this.$t('globalTrans.entry') : this.$t('elearning_config.trainer_evaluation') + ' ' + this.$t('globalTrans.update')
      },
      userTypeList () {
        const userList = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Trainer' : 'প্রশিক্ষক', text_en: 'Trainer', text_bn: 'প্রশিক্ষক' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Trainee' : 'প্রশিক্ষণার্থী', text_en: 'Trainee', text_bn: 'প্রশিক্ষণার্থী' }
        ]
        return userList
      },
      columns () {
        const labelData = this.labelData
        const labels = labelData.map((item, index) => {
          return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
        })
          // const labels = [
          //     { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          //     { label: this.$t('elearning_iabm.registration_for'), class: 'text-left' },
          //     { label: this.$t('elearning_config.fiscal_year'), class: 'text-left' },
          //     { label: this.$t('elearning_iabm.circular_memo_no'), class: 'text-left' },
          //     { label: this.$t('elearning_config.organization'), class: 'text-left' },
          //     { label: this.$t('elearning_config.training_title'), class: 'text-left' },
          //     { label: this.$t('elearning_tim.name'), class: 'text-left' },
          //     { label: this.$t('globalUserData.mobile_no'), class: 'text-left' },
          //     { label: this.$t('globalTrans.action'), class: 'text-center' }
          // ]

          let keys = []

          if (this.$i18n.locale === 'bn') {
              keys = [
                { key: 'serial' },
                { key: 'user_type_name_bn' },
                { key: 'fiscal_year_bn' },
                { key: 'circular_memo_no' },
                { key: 'org_bn' },
                { key: 'training_title_bn' },
                { key: 'name_bn' },
                { key: 'mobile' },
                { key: 'action' }
              ]
          } else {
              keys = [
                { key: 'serial' },
                { key: 'user_type_name' },
                { key: 'fiscal_year' },
                { key: 'circular_memo_no' },
                { key: 'org' },
                { key: 'training_title' },
                { key: 'name' },
                { key: 'mobile' },
                { key: 'action' }
              ]
          }

          return labels.map((item, index) => {
              return Object.assign(item, keys[index])
          })
      },
      pageOptions () {
        return this.$store.state.commonObj.pageOptions
      }
    },
    async created () {
      this.getCircularList()
        this.search = Object.assign({}, this.search, {
            // org_id: this.$store.state.dataFilters.orgId,
            fiscal_year_id: this.$store.state.currentFiscalYearId
        })
        this.loadData()
    },
    watch: {
      'search.fiscal_year_id': function (newValue) {
        this.getCircularList()
      },
      'search.circular_memo_no': function (newValue) {
        this.getCircularMemoNo(newValue)
      }
      // 'search.training_type_id': function (newValue) {
      //   this.trainingCategoryList = this.getCategoryList(newValue)
      // },
      // 'search.training_category_id': function (newValue) {
      //   this.trainingTitleList = this.getTrainingTitleList(newValue)
      // }
    },
    methods: {
      remove (data) {
        localStorage.setItem('DataToGet', JSON.stringify(data))
      },
       note (item) {
        this.item = item
       },
       edit (item) {
        this.item = item
       },
      toggleStatusCustom2 (baseUrl, uri, item) {
        window.vm.$swal({
          title: window.vm.$t('globalTrans.finalSaveConfirmMsg'),
          showCancelButton: true,
          confirmButtonText: window.vm.$t('globalTrans.yes'),
          cancelButtonText: window.vm.$t('globalTrans.no'),
          focusConfirm: false
        }).then((result) => {
          if (result.isConfirmed) {
            this.changeStatusCustom(baseUrl, uri, item)
          }
        })
      },
      changeStatusCustom (baseUrl, uri, item) {
        Store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        RestApi.deleteData(baseUrl, `${uri}/${item.id}`).then(response => {
          if (response.success) {
            window.vm.$toast.success({
              title: 'Success',
              message: 'Data Updated Successfully',
              color: '#D6E09B'
            })
            this.$store.dispatch('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
          } else {
            window.vm.$toast.error({
              title: 'Error',
              message: 'Operation failed! Please, try again.'
            })
          }
          Store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
        })
      },
      async getCircularMemoNo (getCircularMemoNo) {
        this.load = true
        const params = {
          circular_memo_no: getCircularMemoNo,
          table: 'iab_circular_publications'
        }
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularData, params)
        if (!result.success) {
          this.search.org_id = 0
          this.search.training_title_id = 0
          this.search.training_type_id = 0
        } else {
          this.lcErrorMsg = ''
          const trData = result.data
          this.search.org_id = trData.org_id
          this.search.training_title_id = trData.training_title_id
          this.search.training_type_id = trData.training_type_id
          this.load = false
        }
        this.load = false
      },
      async getCircularList () {
        this.circularLoading = true
        const serchData = {
          fiscal_year_id: this.search.fiscal_year_id
        }
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularPublicationList + '/' + 'iab_circular_publications', serchData)
        if (!result.success) {
        } else {
          const listObject = result.data
          const tmpList = listObject.map((obj, index) => {
            if (this.$i18n.locale === 'bn') {
              return { value: obj.circular_memo_no, text: obj.circular_memo_no }
            } else {
              return { value: obj.circular_memo_no, text: obj.circular_memo_no }
            }
          })
          this.circularList = tmpList
        }
        this.circularLoading = false
      },
      getCategoryList (typeId) {
        const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
        if (typeId) {
          return trainingCategoryList.filter(item => item.training_type_id === typeId)
        }
        return trainingCategoryList
      },
      getTrainingTitleList (categoryId) {
        const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
        if (categoryId) {
          return trainingTitleList.filter(item => item.training_category_id === categoryId)
        }
        return trainingTitleList
      },
      dataChange () {
          this.loadData()
      },
      async searchData () {
          this.loadData()
      },
      loadData () {
          const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
          this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
          RestApi.getData(trainingElearningServiceBaseUrl, certificateIndividualList, params).then(response => {
            if (response.success) {
                  this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
                  this.paginationData(response.data, this.search.limit)
              }
              this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
          })
      },
      getCustomDataList (data) {
        const listData = data.map((item, index) => {
          const customItem = {}
          const userTypeObj = this.userTypeList.find(userType => userType.value === item.registration_for)
          if (typeof userTypeObj !== 'undefined') {
            customItem.user_type_name = userTypeObj.text_en
            customItem.user_type_name_bn = userTypeObj.text_bn
          } else {
            customItem.user_type_name = ''
            customItem.user_type_name_bn = ''
          }
          const orgObj = this.$store.state.CommonService.commonObj.componentOrgList.find(doc => doc.value === parseInt(item.org_id))
          const orgData = {}
          if (typeof orgObj !== 'undefined') {
            orgData.org = orgObj.text_en
            orgData.org_bn = orgObj.text_bn
          } else {
            orgData.org = ''
            orgData.org_bn = ''
          }
          const organizationObj = this.$store.state.CommonService.commonObj.componentOrgList.find(doc => doc.value === parseInt(item.orgid))
          const organizationData = {}
          if (typeof organizationObj !== 'undefined') {
            organizationData.organization = organizationObj.text_en
            organizationData.organization_bn = organizationObj.text_bn
          } else {
            organizationData.organization = ''
            organizationData.organization_bn = ''
          }
          const headingObj = this.$store.state.TrainingElearning.commonObj.trainingReportHeadList.find(doc => doc.org_id === parseInt(item.orgid))
          const headingData = {}
          if (typeof headingObj !== 'undefined') {
            headingData.address = headingObj.address
            organizationData.address_bn = headingObj.address_bn
          } else {
            headingData.address = ''
            headingData.address_bn = ''
          }
          const officeObj = this.$store.state.CommonService.commonObj.officeList.find(doc => doc.value === parseInt(item.office_id))
          const officeData = {}
          if (typeof officeObj !== 'undefined') {
            officeData.office = officeObj.text_en
            officeData.office_bn = officeObj.text_bn
          } else {
            officeData.office = ''
            officeData.office_bn = ''
          }

          const fiscalYearObj = this.$store.state.CommonService.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
          const fiscalYearData = {}
          if (typeof fiscalYearObj !== 'undefined') {
            fiscalYearData.fiscal_year = fiscalYearObj.text_en
            fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
            // fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
          } else {
            fiscalYearData.fiscal_year = ''
            fiscalYearData.fiscal_year_bn = ''
          }
          const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
          const trainingTitleData = {}
          if (typeof trainingTitleObj !== 'undefined') {
            trainingTitleData.training_title = trainingTitleObj.text_en
            trainingTitleData.training_title_bn = trainingTitleObj.text_bn
          } else {
            trainingTitleData.training_title = ''
            trainingTitleData.training_title_bn = ''
          }
          const OfficeTypeListObj = this.$store.state.CommonService.commonObj.officeTypeList.find(item1 => item1.value === parseInt(item.office_type_id))
          const OfficeTypeData = {}
          if (typeof OfficeTypeListObj !== 'undefined') {
            OfficeTypeData.office_type = OfficeTypeListObj.text_en
            OfficeTypeData.office_type_bn = OfficeTypeListObj.text_bn
          } else {
            OfficeTypeData.office_type = ''
            OfficeTypeData.office_type_bn = ''
          }
          const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(doc => doc.value === parseInt(item.training_type_id))
          const trainingTypeData = {}
          if (typeof trainingTypeObj !== 'undefined') {
            trainingTypeData.training_type = trainingTypeObj.text_en
            trainingTypeData.training_type_bn = trainingTypeObj.text_bn
          } else {
            trainingTypeData.training_type = ''
            trainingTypeData.training_type_bn = ''
          }
          const trainingCategoryObj = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(doc => doc.value === parseInt(item.training_category_id))
          const trainingCategoryData = {}
          if (typeof trainingCategoryObj !== 'undefined') {
            trainingCategoryData.training_category = trainingCategoryObj.text_en
            trainingCategoryData.training_category_bn = trainingCategoryObj.text_bn
          } else {
            trainingCategoryData.training_category = ''
            trainingCategoryData.training_category_bn = ''
          }
          return Object.assign({}, item, { serial: index }, orgData, organizationData, headingData, officeData, fiscalYearData, trainingTitleData, OfficeTypeData, trainingTypeData, trainingCategoryData, customItem)
        })
        return listData
      },
      pdfExport () {
        this.$refs.details.pdfExport()
      }
    }
}
</script>
